import {API, API_END, API_ERROR, API_INFO, API_START} from "./types";


export const apiStart = payload => ({
    type: API_START,
    payload
});

export const apiEnd = payload => ({
    type: API_END,
    payload
});

export const apiError = error => ({
    type: API_ERROR,
    error
});

export const apiInfo = info => ({
    type: API_INFO,
    info
});

export function apiAction({
                              url = "",
                              method = "GET",
                              data = null,
                              onSuccess = () => {
                              },
                              onFailure = () => {
                              },
                              type = ""
                          }) {
    url = process.env.REACT_APP_SURVEY_SERVICE_URL + url;
    return {
        type: API,
        payload: {
            url,
            method,
            data,
            onSuccess,
            onFailure,
            type
        }
    };
}
