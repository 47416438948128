import {SIGN_IN, SIGN_OUT} from "./types";
import {apiAction} from "./Api";
import axios from 'axios';
// import axios from "axios/index";

const BASE_URL = '/api/user';

export function setAuthorizationToken(token) {
    if (token) {
        axios.defaults.headers.common['X-Auth-Token'] = token;
    } else {
        delete axios.defaults.headers.common['X-Auth-Token'];
    }
}

export function isAuthorized(){

    return localStorage.getItem('jwtToken') !== "null"; 
}

export function login(tokenId, onSuccess) {

    return apiAction({
            url: `${BASE_URL}/auth`,
            method: 'POST',
            data: {tokenId},
            type: SIGN_IN,
            onSuccess: (data) => {
                if(data){
                    localStorage.setItem('jwtToken', data);
                    setAuthorizationToken(localStorage.jwtToken);
                    onSuccess();
                }else{
                    logout();
                    onSuccess();
                }
            }
        }
    );
}

export function logout() {
    localStorage.setItem('jwtToken', null);
    setAuthorizationToken(null);
    return {
        type: SIGN_OUT
    }
}