import React, {useState} from 'react';
import {CircularProgress, Dialog} from '@material-ui/core';
import GoogleLogin from 'react-google-login';
import {login, isAuthorized} from "../../actions/UserActions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

const LoginView = () => {
        const dispatch = useDispatch();
        const history = useHistory();
        const [loading, setLoading] = useState(false);
        let authenticate = (res) => {
            setLoading(true);
            dispatch(
                login(res.tokenId, () => {
                setLoading(false);
                if(isAuthorized()){
                    history.push("/");
                }else{
                    history.push("/login");
                }
            }))
        };


        return (<div style={{
            backgroundColor: "#6e83e4",
            height: "100%",
            width: "100%",
            position: "fixed",
            margin: 0,
            left: 0,
            top: 0
        }}>
            <Dialog open={true}>
                {loading ? <CircularProgress/> :
                    <GoogleLogin
                        clientId="608693597963-thj8ah42d78vgvq2bo2h1voo5e0d8eah.apps.googleusercontent.com"
                        buttonText="Login"
                        onSuccess={authenticate}
                        onFailure={console.log}
                        cookiePolicy={'single_host_origin'}
                    />
                }
            </Dialog>
        </div>);
    }
;

export default LoginView;